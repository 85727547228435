import { prompt } from "../../ai-content/js/requests";

export async function getSummarizedArticle(form, onRead, onDone, onError, setController) {
    if (!document.getElementById("AI_SERVICE_URL")) {
        console.info("Ai feature not available");
        return;
    }
    form.action = form.action ?? "summarizeToSentence";
    form.language = $("#site_language").val() ?? "en";

    try {
        //get article content of form.article_id
        const response = await fetch(`/admin/ajax/article/${form.article_id}/content`);
        const data = await response.json();
        form.text = data.html_content;
    } catch (error) {
        onError(error);
        return;
    }

    await prompt(form, onRead, onDone, onError, setController);
    // await prompt(form, onRead, onDone, onError, control)
}
