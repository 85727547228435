require("./bootstrap");
require("./features/soccerWidgets");
require("./features/dummy-import");
require("./features/notification");

//components
require("./ring-chart");
const Diff = require("diff");

const { renderImage } = require("newsifier-image-url-generator/dist");
const { getSummarizedArticle } = require("./ai-utils/get-summarized-article");

window.getSummarizedArticle = getSummarizedArticle;
function apiUrl() {
    if (window.api_url) return window.api_url;
    window.api_url = $("#service_url").val();
    return window.api_url;
}
window.apiUrl = apiUrl;

window.renderImage = renderImage;
window.Diff = Diff;
