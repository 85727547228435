window._ = require("lodash");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");

    require("bootstrap");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

function string_to_slug(str) {
    str = str.replace("+", " plus");
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

    return str;
}

window.string_to_slug = string_to_slug;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

$(".dropdown.newsifier").on("click", ".dropdown-item", function (event) {
    if (!event.delegateTarget) return;
    var hiddenField = event.delegateTarget.querySelector("input[type=hidden]");
    var toggleLabel = event.delegateTarget.querySelector(".dropdown-toggle .label");
    if (!toggleLabel) return;

    let listItems = event.delegateTarget.querySelectorAll(".dropdown-item");
    listItems.forEach((item) => {
        item.classList.remove("active");
    });
    event.currentTarget.classList.add("active");
    toggleLabel.innerHTML = event.currentTarget.innerHTML;

    // Trigger change event manually
    if (hiddenField) {
        hiddenField.value = event.currentTarget.getAttribute("data-value");
        var event = new Event("change");
        hiddenField.dispatchEvent(event);
    }
});

$(".dropdown.newsifier .search-input-box").on("keyup", function () {
    var value = $(this).val().toLowerCase();
    let searchableWrapper = $("#" + $(this).data("searchable-target"));
    searchableWrapper.find("li").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
});
