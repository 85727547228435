class DummyImporter {
    constructor() {
        const that = this;
        this.$wrapper = null;
        $(function () {
            $('select[data-name="import_method"]').on("change", that.handleImportDummyData.bind(that));
        });
    }

    handleImportDummyData(event) {
        this.hideImportMethods();
        methodName = event.target.value;
        if (methodName) {
            this.showImportMethod(methodName);
        }
        // rss-method-view
    }

    hideImportMethods() {
        $(".import-method-view").addClass("d-none");
    }
    showImportMethod(methodName) {
        console.log(methodName);
        //hide all methods[rss,dummy]
        $(".import-method-view").addClass("d-none");

        this.$wrapper = $(`.import-method-view.${methodName}-method`);
        this.$wrapper.removeClass("d-none");

        this.$wrapper.find("button").off("click", this[`${methodName}ImportHandler`]);
        this.$wrapper.find("button").on("click", this[`${methodName}ImportHandler`].bind(this));
    }

    /**
     * rss import method handler
     * */
    rssImportHandler(e) {
        e.preventDefault();

        rssLink = this.$wrapper.find(".rss-link").val();

        if (!rssLink) {
            this.error({ message: "please insert a correct rss URL" });
            return;
        } else {
            this.$wrapper.find(".form-group").removeClass("has-danger");
            this.$wrapper.find(".control-feedback").addClass("d-none");
        }
        this.setLoader(true);

        that = this;
        this.uploadRSSDummyData(rssLink)
            .then((data) => {
                that.success(data);
            })
            .catch(function (data) {
                that.error(data);
            });
    }

    uploadRSSDummyData(rssLink) {
        const token = $('meta[name="_token"]').attr("content");
        return new Promise((resolve, reject) => {
            $.ajax({
                url: "/admin/ajax/dummy-import/rss",
                method: "POST",
                data: {
                    link: rssLink,
                    _token: token,
                },
                success: function (response) {
                    resolve(response);
                },
                error: function (e) {
                    reject(null);
                },
            });
        });
    }

    /**
     * dummy import method handler
     **/
    dummyImportHandler(e) {
        e.preventDefault();

        this.$wrapper.find(".form-group").removeClass("has-danger");
        this.$wrapper.find(".control-feedback").addClass("d-none");

        this.setLoader(true);
        const token = $('meta[name="_token"]').attr("content");

        that = this;
        $.ajax({
            url: "/admin/ajax/dummy-import/dummy",
            method: "POST",
            data: {
                _token: token,
            },
            success: function (response) {
                that.success(response);
            },
            error: function (e) {
                that.error(response);
            },
        });
    }

    /**
     * general loading UI icon
     **/
    setLoader(show = true) {
        if (show) {
            $('select[data-name="import_method"]').prop("disabled", true);
            this.$wrapper.find("button").prop("disabled", true);
            this.$wrapper.find(".spinner-border").removeClass("d-none");
        } else {
            $('select[data-name="import_method"]').prop("disabled", false);
            this.$wrapper.find("button").prop("disabled", false);
            this.$wrapper.find(".spinner-border").addClass("d-none");
        }
    }
    /**
     * general success errors handler
     **/
    success(data) {
        this.setLoader(false);
        if (data && data.errorsCount == 0) {
            let msg = "";
            if (data && data.migrationCount) {
                msg = `migrate ${data.migrationCount} articles ${
                    data.errorsCount ? "and fail to migrate " + data.errorsCount + " articles" : ""
                }`;
            } else if (data && data.message) {
                msg = data.message;
            }

            if (this.$wrapper) {
                this.$wrapper.find(".form-group").removeClass("has-danger").addClass("has-success");
                this.$wrapper
                    .find(".control-feedback")
                    .text(msg)
                    .removeClass("d-none text-danger")
                    .addClass("text-success");
            } else {
                alert("success");
            }
        } else {
            this.error(data);
        }
    }
    /**
     * general errors handler
     **/
    error(data = null) {
        this.setLoader(false);

        let msg = "";
        if (data && data.errorsCount) {
            msg = `migrate ${data.migrationCount} articles and fail to migrate ${data.errorsCount} articles`;
        } else if (data && data.message) {
            msg = data.message;
        }

        if (this.$wrapper) {
            this.$wrapper.find(".form-group").removeClass("has-success").addClass("has-danger");
            this.$wrapper
                .find(".control-feedback")
                .text(msg)
                .removeClass("d-none text-success")
                .addClass("text-danger");
        } else {
            console.error("field to upload RSS Dummy Data", data);
            alert("field to upload RSS Dummy Data");
        }
    }
}

new DummyImporter();
