import ky from "ky";

const aiServiceUrl = document.getElementById("AI_SERVICE_URL")?.value;
const aiToken = document.getElementById("AI_TOKEN")?.value;
const rewriteAiContentPrompt = document.getElementById("rewrite_prompt")?.value;
const translateAiContentPrompt = document.getElementById("translate_prompt")?.value;
const summarizeAiContentPrompt = document.getElementById("summarize_prompt")?.value;
const summarizeLinkingSentencePrompt = document.getElementById("linking_sentence_summarize_prompt")?.value;
const generateAiContentPrompt = document.getElementById("generate_prompt")?.value;

export async function extract(url) {
    const endpoint = `${aiServiceUrl}/api/v1/extract?url=${url}`;
    return (await ky.get(endpoint, { headers: { Authorization: aiToken }, timeout: false, retry: { limit: 3 } }).json())
        .data;
}

const languagesMap = {
    IT: "Italian",
    EN: "English",
    DE: "German",
    NL: "Dutch",
    PT: "Portuguese",
    FR: "French",
    ES: "Spanish",
    DA: "Danish",
    "pt-PT": "Portuguese (European)",
};

const defaultRewriteAiContentPrompt = "Please rephrase the following text";
const defaultTranslateAiContentPrompt = "Translate the given text";
const defaultSummarizeAiContentPrompt = "Please provide a summary of the given text";
const defaultSummarizeLinkingSentencePrompt = "Please provide short summary of the given text";
const defaultGenerateAiContentPrompt =
    "Make sure the article is optimized for SEO and use same language as the title I provided, example if I provide title in english you generate text in same language so english.";

const PROMPTS = {
    rewrite_same: `${
        rewriteAiContentPrompt || defaultRewriteAiContentPrompt
    }. Ensure that your response is in the same language as the initial text I have provided.`,
    rewrite: `${rewriteAiContentPrompt || defaultRewriteAiContentPrompt} in '<LANGUAGE>'`,
    translate: `${translateAiContentPrompt || defaultTranslateAiContentPrompt} into '<LANGUAGE>'`,
    summarize_same: `${
        summarizeAiContentPrompt || defaultSummarizeAiContentPrompt
    } in the same language as the initial text I have provided.`,
    summarize: `${summarizeAiContentPrompt || defaultSummarizeAiContentPrompt} in '<LANGUAGE>'`,
    summarizeToSentence: `${summarizeLinkingSentencePrompt || defaultSummarizeLinkingSentencePrompt} in '<LANGUAGE>'`,
    generate: `Generate article with title: '<ARTICLE_TITLE>'. ${
        generateAiContentPrompt || defaultGenerateAiContentPrompt
    }`,
    custom: "",
};

export async function prompt(form, onRead, onDone, onError, setController) {
    const endpoint = `${aiServiceUrl}/api/v1/general`;
    let key = form.action;
    if (key === "rewrite" && !form.language) {
        key = "rewrite_same";
    }
    if (key === "summarize" && !form.language) {
        key = "summarize_same";
    }
    const selectedLocal = (form.language || "en").toUpperCase();
    const lang = Object.keys(languagesMap).includes(selectedLocal) ? languagesMap[selectedLocal] : selectedLocal;
    let prompt = PROMPTS[key].replace("<LANGUAGE>", lang);
    prompt +=
        " ,Please do not include in the output any information that is not directly part of the article text like: Publish date information, embedded social posts or embedded videos.";
    if (key == "generate") {
        prompt = prompt.replace("<ARTICLE_TITLE>", form.text);
    } else if (key == "custom") {
        prompt = form.text;
    } else {
        prompt += `:\n\n\n The Article: \n\n\n${form.text}`;
    }
    console.log("Content Prompt", prompt);
    let response = await fetch(endpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: aiToken,
        },
        timeout: false,
        retry: { limit: 3 },
        body: JSON.stringify({ prompt, action: form.action, category: "content", stream: true }),
    });
    const reader = response.body.getReader();
    return new ReadableStream({
        start(controller) {
            if (setController) setController(controller);
            return pump();
            function pump() {
                return reader.read().then(({ done, value }) => {
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                        controller.close();
                        onDone();
                        return;
                    }
                    controller.enqueue(value);
                    const text = new TextDecoder().decode(value);
                    if (text.includes("[ERROR]")) {
                        onError(text);
                    } else {
                        onRead(text);
                    }
                    return pump();
                });
            }
        },
    });
}
