jQuery(function () {
    var $window = $(window);
    renderRingChart();
    $window.on("scroll", renderRingChart);
    window.renderRingChart = renderRingChart;
    function renderRingChart(elements) {
        var rings = elements && elements.length ? elements : $(".ring-chart");

        rings.each(function () {
            var chart = $(this);

            var win_height_padded = $window.height() * 1.1;
            var scrolled = $window.scrollTop();
            var offsetTop = chart.parents(".chart-wrap").offset().top;

            /* Clipping */
            var width = chart.width();
            var height = chart.height();
            var clipmask = "rect(0," + width + "px," + height + "px," + width / 2 + "px)";
            var clipfill = "rect(0," + width / 2 + "px," + height + "px,0)";
            chart.find(".mask").css({
                clip: clipmask,
            });
            chart.find(".mask").find(".fill").css({
                clip: clipfill,
            });

            if (scrolled + win_height_padded > offsetTop) {
                /* Percentage */
                var per = parseInt(chart.attr("data-progress"));
                var csshalf = {
                    "-webkit-transform": "rotate(" + (180 * per) / 100 + "deg)",
                    transform: "rotate(" + (180 * per) / 100 + "deg)",
                };
                var cssfull = {
                    "-webkit-transform": "rotate(" + (360 * per) / 100 + "deg)",
                    transform: "rotate(" + (360 * per) / 100 + "deg)",
                };
                setTimeout(function () {
                    chart.find(".mask.full").css(csshalf);
                    chart.find(".fill").css(csshalf);
                    chart.find(".fix").css(cssfull);
                }, 500);
            }
        });
    }
});
