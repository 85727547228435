export default class Notification {
    constructor(message, type = "success", time = 4000) {
        this.message = message;
        this.type = type;
        this.time = time;
        this.element = null;

        var element = document.createElement("div");
        this.element = element;
        element.className = "toast-notification " + type;

        var countElements = document.getElementsByClassName("toast-notification");
        element.style.marginBottom = countElements.length * 55 + "px";
        element.style.width = this.message.length * 10 + "px";

        var message = document.createElement("div");
        message.className = "message-container";
        message.innerHTML = this.message;

        element.appendChild(message);

        var close = document.createElement("div");

        close.className = "close-notification";

        var icon = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>`;

        close.innerHTML = icon;
        element.append(close);

        close.addEventListener("click", () => {
            element.remove();
        });
    }

    show() {
        document.body.appendChild(this.element);
        const that = this;
        setTimeout(function () {
            that.element.remove();
        }, that.time);
    }
}

window.notify = function () {
    new Notification(...arguments).show();
};
